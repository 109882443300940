<template>
    <div class="field-table flex-1 flex flex-col  max-h-full max-w-full  min-h-full text-base-content">
        <SubHeader />
        <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">
            <vue-good-table 
                :columns="computedColumns" 
                :isLoading="loading" 
                :rows="rows" 
                :pagination-options="{ enabled: true, mode: 'records', dropdownAllowAll: false}"
                @on-column-filter="onColumnFilter" 
                v-bind="config" 
                :totalRows="pageData.total_count"
                @on-page-change="onPageChange($event)"
                @on-per-page-change="onPageChange($event, true)"
                >
                <template #column-filter="{ column, updateFilters }">
                    <div v-if="column.filterOptions && column.filterOptions.enabled && column.config && column.config.filter_type === 'date'">
                        <DatePicker range v-model="date_range" @input="(value) => updateColumnFilters(column, value, updateFilters)" 
                        :key="date_range_key" class="w-full" format="DD MMM, YYYY" value-type="timestamp"  :placeholder="due_date" />
                    </div>
                    <dropdown
                        :ref="setFilterRef"
                        reposition
                        searchable
                        class="filter-dropdown"
                        :placeholder="$t('general.dropdown_placeholder')"
                        v-else-if="column.filterOptions && column.filterOptions.enabled && column.config && !['indicators', 'allocation'].includes(column.config.type)"
                        :options="column.filterOptions.filterDropdownItems"
                        @input="(value) => updateColumnFilters(column, value, updateFilters)"
                        :value="getFilterValue(column)"
                        @search="searchFilter(column, $event)"
                        :limit="1"
                        maxWidth="100%"
                        width="100px"
                        minWidth="100%"
                        :config="{ label: 'name', trackBy: 'id' }"
                        :multiple="false"
                        :taggable="false"
                    >
                        <template #caret>
                          <sort-icon class="fill-current" />
                        </template>
                    </dropdown>
                    <div class="" v-else>
                        <!-- // adding a blank div to remove the default template   -->
                    </div>
                </template>
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'unique_id'"
                        :class="[props.row.system_default && 'default hover-text']" class="flex gap-3 pl-3">
                        <router-link :to="{
                                name: 'inventoryDetails',
                                params: {id: props.row.credit_pack_id}
                            }" class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron">
                                {{ props.row.unique_id}}
                        </router-link>
                    </div>
                    <div v-if="props.column.field == 'credit_pack_name'" class="flex justify-center">
                        {{props.row.credit_pack_name}}
                    </div>
                    <div v-if="props.column.field == 'validity'" class="flex justify-center">
                        {{props.row.validity}} Days
                    </div>
                    <div v-if="props.column.field == 'total_credits'" class="flex justify-center">
                        {{props.row.total_credits}}
                    </div>
                    <div v-if="props.column.field == 'remaining_credits'" class="flex justify-center">
                        {{props.row.remaining_credits}}
                    </div>
                    <div v-if="props.column.field == 'tenant_order_id'" class="flex justify-center">
                        {{props.row.tenant_order_id}} 
                    </div>
                    <div v-if="props.column.field == 'order_date'" class="flex justify-center">
                        {{ FormatDateNew(new Date(props.row.order_date)) }}
                    </div>
                    <div v-if="props.column.field == 'client_name'" class="flex justify-center">
                        {{ props.row.client_name }}
                    </div>
                    <div v-if="props.column.field == 'client_order_id'" class="flex justify-center">
                        {{props.row.client_order_id}} 
                    </div>
                    <div v-if="props.column.field == 'sale_date'" class="flex justify-center">
                        {{ props.row.sale_date ? FormatDateNew(new Date(props.row.sale_date)) : '-' }}
                    </div>
                    <div v-if="props.column.field == 'activated_at'" class="flex justify-center">
                        {{ props?.row?.activated_at ? FormatDateNew(new Date(props?.row?.activated_at)) : '-' }}
                    </div>
                    <div v-if="props.column.field == 'valid_till'" class="flex justify-center">
                        {{ props?.row?.valid_till ? FormatDateNew(new Date(props?.row?.valid_till)) : '-' }}
                    </div>
                    <div v-if="props.column.field == 'status'" class="flex justify-center capitalized">
                        <span :class="getStatusColor(props.row.status)">{{ props.row.status }}</span>
                    </div>
                    <div v-if="props.column.field == 'action' && props.row.status != 'Sold Out'" class="flex justify-center cursor-pointer" @click="activatePack(props.row.credit_pack_id)">
                        <span class="text-red-400">{{ props.row.action }}</span>
                    </div>
                </template>

                <template #loadingContent>
                    <div class="w-full py-10 justify-center flex">
                        <Loader />
                    </div>
                </template>
            </vue-good-table>
            <button 
                v-if="isFilters"
                class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute z-50 left-2" :class="isSelectionChanged ? 'top-14' : 'top-2' " @click="clearFilters">
                {{ $t('general.clear_filters') }}
            </button>
        </div>
    </div>
</template>
<script>
    import SubHeader from "@/components/SubHeader";
    import {getCreditPackInventory, activateCreditPack} from "../services"
    import Loader from "@shared/loader";
    import DatePicker from "vue2-datepicker";
    import dropdown from "@shared/components/dropdown-base";
    import {FormatDateNew} from "@/plugins/functions";
    import { onlyUnique } from "@shared/plugins/utils.js";
    import { uuid } from "vue-uuid";
    import { DateTime } from "luxon";
    import { format , parse } from "date-fns";

    export default {
      name: "credit-packs",
      title: "Credit Admin",
      components: {
          SubHeader,
          Loader,
          DatePicker,
          dropdown,
      },
      data() {
          return {
              columns: [{
                  label: "Uniuqe Id",
                  field: "unique_id",
                  // sortable: false,
                  config: {
                      filter: true,
                      alignCenter: true,
                      field_type: 'action_button',
                  },
              },{
                  label: "Pack Name",
                  field: "credit_pack_name",
                  // sortable: false,
                  config: {
                      filter: true,
                      alignCenter: true,
                      field_type: 'action_button',
                  },
              }, {
                  label: "Credits",
                  field: "total_credits",
                  // sortable: false,
                  // filterOptions: {
                  //     enabled: true,
                  // },
              },{
                  label: "Available Credits",
                  field: "remaining_credits",
                  // sortable: false,
                  // filterOptions: {
                  //     enabled: true,
                  // },
              },
               {
                  label: "Validity",
                  field: "validity",
                  // sortable: false,
              }, {
                  label: "Tenant Order ID",
                  field: "tenant_order_id",
                  config: {
                      filter: true,
                      alignCenter: true,
                      field_type: 'action_button',
                  },
              }, {
                  label: "Purchase Date",
                  field: "order_date",
                  // config: {
                  //     filter: true,
                  //     alignCenter: true,
                  //     field_type: 'show_date',
                  // },
              },{
                  label: "Client Name",
                  field: "client_name",
                  config: {
                      filter: true,
                      alignCenter: true,
                      field_type: 'action_button',
                  },
              },{
                  label: "Client Order ID",
                  field: "client_order_id",
                  config: {
                      filter: true,
                      alignCenter: true,
                      field_type: 'action_button',
                  },
              },{
                  label: "Sale Date",
                  field: "sale_date",
                  // config: {
                  //     filter: true,
                  //     alignCenter: true,
                  //     field_type: 'show_date',
                  // },
              },
              { 
                label: "Start Date", 
                field: "activated_at",
                config: {
                    filter: true,
                    alignCenter: true,
                    field_type: 'show_date',
                  },  
                },
                { 
                  label: "Expiry Date", 
                  field: "valid_till",
                  config: {
                    filter: true,
                    alignCenter: true,
                    field_type: 'show_date',
                  }, 
                },{
                  label: "Status",
                  field: "status",
                  // sortable: false
              }, 
              { 
                  label: "Action", 
                  field: "action"
              },
              ],
              rows: [],
              serverParams: {},
              filterRefs: [],
              filterOptions: {},
              searchLoading:{},
              isSelectionChanged: false,
              pageData: {
                  total_count: 0,
              },
              currentPageData: {
                  page: 1,
                  limit: 10,
              },
              // params: {
              //     page: 1,
              //     limit: 10,
              // },
              loading: false,
              
          }
      },
      async mounted() {
        this.loading = true
        await this.setCreditPacks();
        this.loading = false
      },
      computed: { 
          isFilters() {
            return this.serverParams && Object.entries(this.serverParams).length;
          },
          columnFilterOptions() {
            return this.getFilterOptions(this.columnOption, this.rows);
          },
          columnOption() {
            return this.columns.map((el) => ({
              ...el,
              config: 
              {
                  ...el.config,
                  isLoading: !!this.searchLoading[el.field] || false,
              },
            }));
          },
          computedColumns() {
            // use option list from server if it is:
            const options = this.columnFilterOptions;
            // had to check for empty object
            if (options && Object.entries(options).length) {
              return this.columns.map((col) => {
                const filtered = col.config?.filter ?? true;
                if (col.prefetchedOptions && col.prefetchedOptions.length) {
                  return {
                    ...col,
                    filterOptions: {
                      enabled: filtered,
                      filterDropdownItems: col.prefetchedOptions,
                    },
                  };
                } else {
                  return {
                    ...col,
                    filterOptions: {
                      enabled: filtered,
                      filterDropdownItems: options[col.field],
                    },
                  };
                }
              });
            } else {
              // TODO remove/rework default filters
              return this.columns.map((col) => {
                const filtered = col.config?.filter ?? true;
                return {
                  ...col,
                  filterOptions: filtered
                    ? {
                      enabled: true,
                      placeholder: "All",
                      filterDropdownItems: this.rows
                        .filter((row) => row[col.field])
                        .map((row) => ({ id: row.field_type_id ?? uuid.v4(), name: row[col.field] }))
                        .filter(onlyUnique),
                    }
                    : undefined,
                };
              });
            }
          }
      },
      methods: {
        FormatDateNew,
        async setCreditPacks() {
          const filterParams = this.makeFilterParams();
          let qParams
          qParams = new URLSearchParams(this.currentPageData);
          if (filterParams) {
              qParams = new URLSearchParams({...this.currentPageData, ...filterParams});
          }
          this.tenantId = this.$store.getters.getTenantId;
          const {data} = await getCreditPackInventory(this.tenantId, qParams)
          this.rows = data.credit_packs_inventory
          this.pageData = data?.pagination_info;
        },
        getStatusColor(status) {
          if (status.toLowerCase() == "in use") {
            return "text-yellow-400"
          }
          if (status.toLowerCase() == "sold out") {
            return "text-red-400"
          }
          if (status.toLowerCase() == 'available') {
            return 'text-green-400'
          }
        },
        async clearFilters() {
          this.serverParams = null;
          await this.setCreditPacks();
        },
        async onColumnFilter({ columnFilters }) {
          this.currentPageData.page = 1;
          this.serverParams = columnFilters ? { ...columnFilters } : null;
          await this.setCreditPacks();
        },
        selectionChanged(e) {
          // console.log('e :>> ', e);
          if (e) {
            this.isSelectionChanged = e.selectedRows && e.selectedRows.length !== 0;
            this.$emit('on-selected-rows-change', e.selectedRows)
          }
        },
        getFilterOptions(columns, row) {
            return columns.reduce((result, curr) => {
              result[curr.field] = row
                .filter((row) => row[curr.field])
                .map((row) => row[curr.field])
                .filter(onlyUnique)
                .map((el) => (
                  { 
                    id: uuid.v4(), name: el 
                  }));
                  if (curr?.field == 'order_date' || curr?.field == 'sale_date') {
                    result[curr.field] = result[curr.field]?.map(el => {
                      return {
                        ...el,
                        name: FormatDateNew(new Date(el.name)),
                        label: el.name
                      }
                    })
                  }
                  return result;
                }, 
            {});
        },
        makeFilterParams() {
            let filterParams = {};
            if (this.serverParams) {
              Object.keys(this.serverParams).map((key) => {
                if (key == 'order_date' || key == 'sale_date') {
                  filterParams[key] = this.serverParams[key] ? this.serverParams[key]["label"] : delete filterParams[key];
                } else {
                  filterParams[key] = this.serverParams[key] ? this.serverParams[key]["name"] : delete filterParams[key];
                }
                if (this.serverParams[key]["name"] === undefined) {
                  delete filterParams[key];
                }
              });
            }
            return filterParams;
        },
        updateColumnFilters(column, value /* , callback */) {
            let filterValObj = {
              ...this.serverParams,
              [column.query_key || column.field]: value ? {
                ...value,
                field_type: column.field_type,
              } : null,
            };
            if (!filterValObj[column.query_key || column.field]) {
              delete filterValObj[column.query_key || column.field];
            }
            if (column.query_key && ["from_due_date", "from_created_at"].includes(column.query_key)) {
              let date = DateTime.fromISO(value.name).toISODate()
              if (date == null) {
                const parsedDate = parse(value.name, 'd MMM yyyy', new Date());
                date = format(parsedDate, 'yyyy-MM-dd');
                if (filterValObj.from_created_at) {
                  filterValObj.from_created_at.name = date.toString().substring(10, 0)
                } else {
                  filterValObj.from_due_date.name = date.toString().substring(10, 0)
                }
              }
              Object.assign(filterValObj, {
                [`to_${column.field}`]: {
                  id: date.ts,
                  name: date.toString().substring(10, 0),
                  field_type: column.field_type
                },
              });
            }
            if (column.config && /* column.config.query_keys && */ column.config.filter_type === 'date') { // uncommment when you want to add customkeys for backend filters
              let [start, end] = value.map(el => DateTime.fromJSDate(new Date(el)))
              Object.assign(filterValObj, {
                [column.config.query_keys['start']]: {
                  id: start.ts,
                  name: value[0] ? start.toString() : null,
                  field_type: column.field_type
                },
              })
              Object.assign(filterValObj, {
                [column.config.query_keys['end']]: {
                  id: end.ts,
                  name: value[1] ? end.toString() : null,
                  field_type: column.field_type
                },
              })
            }
            this.onColumnFilter( {
              columnFilters: filterValObj,
            });
        },
        async onPageChange(data, resetPage = false) {
          this.currentPageData = {
            page: data.currentPage,
            limit: data.currentPerPage,
          };
          if (resetPage) this.currentPageData.page = 1;
          await this.setCreditPacks();
        },
        searchFilter(column, value) {
          this.$emit("search-filter", { column, value });
        },
        setFilterRef(el) {
          if (el) {
            this.filterRefs.push(el);
          }
        },
        async activatePack(creditpack){
          try {
            this.loading = true
            let { data } = await activateCreditPack(creditpack);
            this.$toast.success(data ? data?.message : "Pack Activated");
            await this.setCreditPacks();
            this.loading = false
          }
          catch(error) {
            if (Array.isArray(error?.response?.data?.detail)) {
              this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
            } else {
              this.$toast.error(error?.response?.data?.detail);
            }
            this.loading = false
          }
        },
        dateFilterFn(data, filterString) {
          let givenDate = FormatDateNew(new Date(data)).toLowerCase();
          return givenDate.includes(filterString.toLowerCase());
        },
        getFilterValue(col) {
          // isFilters ? filters[column.field] : null
          if (this.isFilters) {
            return this.serverParams[col.field] ?? this.serverParams[col.query_key];
          } else return null;
        },
      }
    }
</script>
