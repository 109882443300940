<template>
    <Modal ref="assignedListModal" :title="getTitle" :showFooter='false'>
        <template #content>
            <div class="flex border-2 items-center rounded-lg h-10 pl-3">
                <span class="input-group-text left-3 top-3  text-gray-400 " >
                    <font-awesome-icon icon="search"> </font-awesome-icon>
                </span>
                <input
                    type="text" 
                    :placeholder="$t('general.search')"
                    class="input input-sm w-full"
                    v-model="searchedValue"
                    @input="handleSearch(searchedValue)"
                />
            </div>
            <div class="grid gap-2"> 
                <div class="w-full overflow-hidden rounded-lg bg-white border-2 mt-2 flex items-center justify-center" :class="{'listData' : modalData?.length == 0}">
                    <div class="overflow-auto max-h-96 w-full" v-if="modalData?.length > 0">
                        <table class="min-w-full bg-white" id="dataTable">
                        <tbody>
                            <tr v-for="(rowData, clntInx) in modalData" :key="clntInx" class="border-b tableRows">
                                <td class="pl-5">
                                    {{ rowData.tenant_name ? rowData?.tenant_name : rowData?.client_name }}
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <div v-else class="flex justify-center">
                        <span>No {{title}} are assigned</span>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
    import Modal from "@/components/modal-content";

    export default {
        name: "Tenant-Client-Modal",
        props: {
            title: {
                type: String,
                default: () => '',
            },
            modalData: {
                type: Array,
                default: () => [],
            }
        },
        components: {
            Modal,
        },
        data: () => ({
            searchedValue: null,
        }),
        computed: {
            getTitle() {
                return 'Assigned'+' '+this.title;
            }
        },
        mounted() {
        },
        methods: {
            showModal() {
                console.log('modalData--', this.modalData);
                this.$refs["assignedListModal"].showModal();
            },
            handleSearch(searchedText) {
                let value = searchedText.toLowerCase();
                let table = document.getElementById("dataTable");
                let rows = table.getElementsByClassName("tableRows");
                for (let i = 0; i < rows.length; i++) {
                  let tenant_row = rows[i];
                  let cells = tenant_row.getElementsByTagName("td");
                  let found = false;
                  for (let i = 0; i < cells.length; i++) {
                    let cell = cells[i];
                    if (cell.innerHTML.toLowerCase().indexOf(value) > -1) {
                      found = true;
                      break;
                    }
                  }
                  if (found) {
                    tenant_row.style.display = "";
                  } else {
                    tenant_row.style.display = "none";
                  }
                }
            },
        }
    };
</script>
<style lang="scss" scoped>
    .tableRows {
        height: 40px;
    }
    .listData {
        min-height: 100px;
    }
</style>