<template>
    <div class="field-table flex-1 flex flex-col max-h-full max-w-full min-h-full text-base-content">
        <SubHeader :buttonText="$t('manage_tax.create_tax')" :handleClick="createTax" :showBtn="true" />
        <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">

          <vue-good-table
            :isLoading="loadingTaxes"
            :columns="computedColumns"
            :rows="rowsData"
            :pagination-options="{ enabled: true, mode: 'records', dropdownAllowAll: false}"
            @on-column-filter="onColumnFilter"
            v-bind="config"
            :totalRows="totalRows"
            @on-per-page-change="onPageChange($event, true)"
            @on-page-change="onPageChange($event)"
            @on-selected-rows-change="selectionChanged"
          >
            <template #column-filter="{ column, updateFilters }">
              <dropdown
                :ref="setFilterRef"
                reposition
                searchable
                class="filter-dropdown"
                :placeholder="$t('manage_tax.dropdown_placeholder')"
                v-if="
                  column.filterOptions &&
                  column.filterOptions.enabled &&
                  column.config &&
                  !['indicators', 'allocation'].includes(column.config.type)
                "
                :options="column.filterOptions.filterDropdownItems"
                @input="(value) => updateColumnFilters(column, value, updateFilters)"
                :value="getFilterValue(column)"
                @search="searchFilter(column, $event)"
                :limit="1"
                maxWidth="100%"
                width="100px"
                minWidth="100%"
                :config="{ label: 'name', trackBy: 'id' }"
                :multiple="false"
                :taggable="false"
              >
                <template #caret>
                  <sort-icon class="fill-current" />
                </template>
              </dropdown>
              <div class="" v-else>
                <!-- // adding a blank div to remove the default template   -->
              </div>
            </template>
            <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field === 'tax_name'" :class="[props.row.system_default && 'default hover-text']"
                    class="flex gap-3">
                    <router-link :to="{
                        name: 'manageTaxInfo',  
                        params: {id: props.row.id}
                    }"
                        class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron">{{ props.row.tax_name }}
                    </router-link>
                </div>
                <div v-if="props.column.field == 'rate'">
                    {{ props.row.rate }}                
                </div>
                <div v-if="props.column.field == 'tenants'" class="flex justify-center cursor-pointer"
                    @click="assignedTenants(props.row.clients)">
                    <div class="indicator">
                        <span class=" w-2 h-4 indicator-item badge badge-primary">{{ props.row.clients.length }}</span>
                        <font-awesome-icon class="w-6  mx-2" icon="user" />
                    </div>
                </div>
            </template>
            <template #loadingContent>
                <div class="w-full py-10 justify-center flex">
                    <Loader />
                </div>
            </template>
          </vue-good-table>
          <button 
            v-if="isFilters"
            class="cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute z-50 left-2" 
            :class="isSelectionChanged ? 'top-14' : 'top-2' " 
            @click="clearFilters">
            {{ $t('actions.clear_filters') }}
          </button>
        </div>
        <Modal ref="assigned-tenants" title='Assigned Tenants' :showFooter='false'>
            <template #content>
                <div class="grid gap-2">
                    <span class="border-b text-bold" v-for="data in clientsList" :key="data">{{ data.name }}</span>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import {getTaxList} from "./services" 
import SubHeader from "@/components/SubHeader"
import Modal from "@/components/modal-content"
import Loader from "@shared/loader";
import dropdown from "@shared/components/dropdown-base";
import { onlyUnique } from "@shared/plugins/utils.js";
import { uuid } from "vue-uuid";
import { DateTime } from "luxon";
import { format, parse } from "date-fns";
export default {
    name: "taxList",
    data: () => {
      return {
        rowsData: [],
        columns: [{
            label: "Tax Name",
            field: "tax_name",
            filterOptions: {
              enabled: true,
            },
            config: {
              filter: true,
              alignCenter: true,
              field_type: "action_button",
            },
        }, {
            label: "Rate(%)",
            field: "rate",
            filterOptions: {
              enabled: true,
            },
            config: {
              filter: true,
              alignCenter: true,
              field_type: "action_button",
            },
        }, {
            label: "Assigned Tenants",
            field: "tenants"
        }],
        loadingTaxes: false,
        clientsList: [],
        params: {
            page: 1,
            limit: 10,
        },
        totalRows: 0,
        filters: {},
        serverParams: {},
        searchLoading:{},
        date_range: [],
        filterRefs: [],
        filterOptionsAsync: {},
        filterOptions: {},
        isSelectionChanged: false
      }
    },
    components: { 
        SubHeader, 
        Loader, 
        Modal,
        dropdown
    },
    async mounted() {
      this.loadingTaxes = true
      await this.setTaxes()
      this.filterOptions = this.columnFilterOptions
      this.loadingTaxes = false
    },
    computed: {
      isFilters() {
        return this.serverParams && Object.entries(this.serverParams).length;
      },
      columnFilterOptions() {
        return this.getFilterOptions(this.columnOption, this.rowsData);
      },
      columnOption() {
        return this.columns.map((el) => ({
          ...el,
          config: {
            ...el.config,
            isLoading: !!this.searchLoading[el.field] || false,
          },
        }));
      },
      computedColumns() {
        // use option list from server if it is:
        const options = this.filterOptions;
        // had to check for empty object
        if (options && Object.entries(options).length) {
          return this.columns.map((col) => {
            const filtered = col.config?.filter ?? true;
            if (col.prefetchedOptions && col.prefetchedOptions.length) {
              return {
                ...col,
                label: this.$t(`manage_tax.column_labels.${col.field}`),
                filterOptions: {
                  enabled: filtered,
                  filterDropdownItems: col.prefetchedOptions,
                },
              };
            } else {
              return {
                ...col,
                label: this.$t(`manage_tax.column_labels.${col.field}`),
                filterOptions: {
                  enabled: filtered,
                  filterDropdownItems: options[col.field],
                },
              };
            }
          });
        } else {
          // TODO remove/rework default filters
          return this.columns.map((col) => {
            const filtered = col.config?.filter ?? true;
            return {
              ...col,
              label: this.$t(`manage_tax.column_labels.${col.field}`),
              filterOptions: filtered
                ? {
                  enabled: true,
                  placeholder: "All",
                  filterDropdownItems: this.rowsData
                    .filter((row) => row[col.field])
                    .map((row) => ({ id: row.field_type_id ?? uuid.v4(), name: row[col.field] }))
                    .filter(onlyUnique),
                }
                : undefined,
            };
          });
        }
      }
    },
    methods: {
      createTax() {
          this.$router.push({ name: "manageTaxInfo" })
      },
      assignedTenants(clients) {
          this.clientsList = clients
          this.$refs["assigned-tenants"].showModal();
      },
      async setTaxes() {
          const filterParams = this.makeFilterParams();
          this.loadingTenants = true;
          let qParams
          qParams = new URLSearchParams(this.params);
          if (filterParams) {
              qParams = new URLSearchParams({ ...this.params, ...filterParams });
          }
          const {data} = await getTaxList(qParams)
          this.rowsData = data.tax_data
          this.totalRows = data?.pagination_info.total_count;
          this.loadingTaxes = false;
      },
      makeFilterParams() {
          let filterParams = {};
          if (this.serverParams) {
              Object.keys(this.serverParams).map((key) => {
                  filterParams[key] = this.serverParams[key]
                  ? this.serverParams[key]["name"]
                  : delete filterParams[key];
                  if (this.serverParams[key]["name"] === undefined) {
                      delete filterParams[key];
                  }
                  if (key.includes("date_from") || key.includes("date_to")) {
                      filterParams[key] = this.formatDate(filterParams[key]);
                  }
              });
          }
          return filterParams;
      },
      async clearFilters() {
                  this.serverParams = null;
                  await this.setTaxes();
      },
      async onColumnFilter({ columnFilters }) {
                  this.params.page = 1;
                  this.serverParams = columnFilters ? { ...columnFilters } : null;
                  await this.setTaxes();
      },
      selectionChanged(e) {
          if (e) {
              this.isSelectionChanged = e.selectedRows && e.selectedRows.length !== 0;
              this.$emit('on-selected-rows-change', e.selectedRows)
          }
      },
      getFilterOptions(columns, row) {
        return columns.reduce((result, curr) => {
          result[curr.field] = row
            .filter((row) => row[curr.field])
            .map((row) => row[curr.field])
            .filter(onlyUnique)
            .map((el) => ({ id: uuid.v4(), name: el }));
          return result;
        }, {});
      },
      updateColumnFilters(column, value /* , callback */) {
        let filterValObj = {
          ...this.serverParams,
          [column.query_key || column.field]: value ? {
            ...value,
            field_type: column.field_type,
          } : null,
        };
        if (!filterValObj[column.query_key || column.field]) {
          delete filterValObj[column.query_key || column.field];
        }
        if (column.query_key && ["from_due_date", "from_created_at"].includes(column.query_key)) {
          let date = DateTime.fromISO(value.name).toISODate()
          if (date == null) {
            const parsedDate = parse(value.name, 'd MMM yyyy', new Date());
            date = format(parsedDate, 'yyyy-MM-dd');
            if (filterValObj.from_created_at) {
              filterValObj.from_created_at.name = date.toString().substring(10, 0)
            } else {
              filterValObj.from_due_date.name = date.toString().substring(10, 0)
            }
          }
          Object.assign(filterValObj, {
            [`to_${column.field}`]: {
              id: date.ts,
              name: date.toString().substring(10, 0),
              field_type: column.field_type
            },
          });
        }
        if (column.config && /* column.config.query_keys && */ column.config.filter_type === 'date') { // uncommment when you want to add customkeys for backend filters
          let [start, end] = value.map(el => DateTime.fromJSDate(new Date(el)))
          Object.assign(filterValObj, {
            [column.config.query_keys['start']]: {
              id: start.ts,
              name: value[0] ? start.toString() : null,
              field_type: column.field_type
            },
          })
          Object.assign(filterValObj, {
            [column.config.query_keys['end']]: {
              id: end.ts,
              name: value[1] ? end.toString() : null,
              field_type: column.field_type
            },
          })
        }
        this.onColumnFilter( {
          columnFilters: filterValObj,
        });
      },
      async onPageChange(data, resetPage = false) {
        if (resetPage) {
          this.params.page = 1;
        } else this.params.page = data.currentPage;
        this.params.limit = data.currentPerPage;
        await this.setTaxes();
      }, 
      searchFilter(column, value) {
              this.$emit("search-filter", { column, value });
      },
      setFilterRef(el) {
        if (el) {
            this.filterRefs.push(el);
        }
      },
      getFilterValue(col) {
        // isFilters ? filters[column.field] : null
        if (this.isFilters) {
          return this.serverParams[col.field] ?? this.serverParams[col.query_key];
        } else return null;
      },
    }
}
</script>

<style scoped lang='scss'>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>