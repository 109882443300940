<template>
    <div class="field-table flex-1 flex flex-col  max-h-full max-w-full  min-h-full text-base-content">
        <SubHeader 
            :showDropDown="true"
            :dropDownOptions="dropDownOptions"
            :handleDropDownSelection="handleFilterActivity"
            :customCrumbLists="customCrumbLists"
        />
        <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">
            <vue-good-table 
              :columns="columns" 
              :isLoading="loading" 
              :rows="filteredRowsData" 
              :pagination-options="{ enabled: true, mode: 'remote', dropdownAllowAll: false}"
              :totalRows="pageData.total"
              @on-page-change="onPageChange($event)"
              @on-per-page-change="onPageChange($event, true)"
              :per-page-options="customPerPageOptions"
            >
              <template #column-filter="{ column }">
                <dropdown
                  :ref="setFilterRef"
                  reposition
                  searchable
                  class="filter-dropdown"
                  :placeholder="$t('general.dropdown_placeholder')"
                  v-if="column?.filterOptions && column?.filterOptions?.enabled"
                  :options="getFilterOptions(column)"
                  @search="searchFilter(column, $event)"
                  @input="(value) => updateColumnFilters(column, value)"
                  :value="filters[column.field]"
                  :limit="1"
                  maxWidth="100%"
                  width="100px"
                  minWidth="100%"
                  :config="{ label: 'name', trackBy: 'id' }"
                  :multiple="false"
                  :taggable="false"
                  >
                  <template #caret>
                    <sort-icon class="fill-current" />
                  </template>
                </dropdown>
                <div class="" v-else>
                  <!-- // adding a blank div to remove the default template   -->
                </div>
              </template>
              <template slot="table-row" slot-scope="props">
                  <div v-if="props.column.field == 'credit_source_name'" class="ml-3" >
                    <router-link
                    :to="{
                        name: 'source_details',
                        params: { id: props.row.id },
                        query: { credit_source_id: props.row.id },
                    }"
                    class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron"
                    >{{ props.row.display_name }}
                  </router-link>
                  </div>
                  <div v-if="props.column.field === 'input'" class='' :title="props.row.input">
                      {{ getNrnName(props.row.input) }}
                  </div>
                  <div v-if="props.column.field === 'output'" class='' :title="props.row.output">
                      {{ getNrnName(props.row.output) }}
                  </div>
                  <div v-if="props.column.field == 'credit_per_hit'" class="flex justify-center" :class="{'text-primary' : checkCustomData(props.row)}">
                      {{ showCreditHit(props.row) }}
                  </div>
                  <!-- <div v-if="props.column.field == 'service_charge_per_hit'" class="flex justify-center" :class="{'text-primary' : checkCustomData(props.row)}">
                      {{ showServiceCharge(props.row) }}
                  </div> -->
                  <div v-if="props.column.field == 'clients'" class="flex justify-center cursor-pointer" @click="assignedClients(props.row.custom_clients)">
                    <div class="indicator">
                    <span class="absolute bottom-1.5 left-5 rounded-full px-1.5 py-0.5 badge-primary text-xs ">{{props.row.custom_clients.length}}</span> 
                    <font-awesome-icon class="w-6  mx-2" icon= "user"/>
                </div>
            </div>
              </template>
              <template #emptystate>
                  <div class="alert alert-info py-5 my-5" v-if="!loading">No Data Found</div>
                  <div v-else class="py-16"></div>
              </template>
              <template #loadingContent>
                  <div class="w-full py-10 justify-center flex">
                      <Loader />
                  </div>
              </template>
            </vue-good-table>
            <button 
              v-if="Object.values(filters).length"
              class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute z-50 left-2" :class="isSelectionChanged ? 'top-14' : 'top-2' " @click="clearFilters">
              Clear filters
            </button>
        </div>
        <showTenantClientModal
        ref="assignedListModal"
        :title="currentModalTitle"
        :modalData="modalData"
    />
    </div>
</template>
<script>
    import SubHeader from "@/components/SubHeader";
    import { creditAndSourceList, getclientId } from "../services"
    import dropdown from "@shared/components/dropdown-base";
    import Loader from "@shared/loader";
    import { onlyUnique } from "@shared/plugins/utils.js";
    import { uuid } from "vue-uuid";
    // import { DateTime } from "luxon";
    // import { format , parse } from "date-fns";
    import {FormatDateNew} from "@/plugins/functions";
    import showTenantClientModal from "./tenantClientModal.vue";

    export default {
        name: "credit-packs",
        title: "Credit Admin",
        components: {
            SubHeader,
            Loader,
            dropdown,
            showTenantClientModal
        },
        data() {
            return {
                columns: [{
                    label: "Feature",
                    field: "credit_source_name",
                    sortable: true,
                    filterOptions: {
                      enabled: true, // enable filter for this column
                      multi: true,
                      isLoading: false,
                      query_key: "credit_source_name",
                    },
                },
                {
                    label: "Input",
                    field: "input",
                    sortable: true,
                    // filterOptions: {
                    //   enabled: true, // enable filter for this column
                    //   multi: true,
                    //   isLoading: false,
                    //   query_key: "sub_feature_name",
                    // },
                },{
                    label: "Output",
                    field: "output",
                    // sortable: true
                },
                {
                    label: "Credits/Hits",
                    field: "credit_per_hit",
                    // sortable: true
                },
                // {
                //     label: "Service Charge/Hits",
                //     field: "service_charge_per_hit",
                //     // sortable: true
                // } 
                {
                    label: "Custom Client",
                    field: "clients",
                    filter_type: "client_users",
                    sortable: true,
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "clients",
                    },
                },
                ],
                
                showDropDown: false,
                dropDownOptions: {
                    options: [],
                    label: 'title',
                    trackBy: 'value',
                },
                rows: [],
                filteredRowsData: [],
                filters: {},
                serverParams: {},
                filterRefs: [],
                filterOptions: {},
                searchLoading:{},
                clients: [],
                pageData: {
                    total: 0,
                },
                currentPageData: {
                    page: 1,
                    limit: 10,
                },
                customPerPageOptions: [10, 20, 30, 40, 50],
                loading: false,
                customCrumbLists: [{name: 'Feature'}],
                currentClient: {},
                tenantId: null,
                isSelectionChanged: false,
                modalData: [],
                currentModalTitle: "",
            }
        },
        async mounted() {
            this.loading = true
            await this.setCreditSourceList()
            this.filteredRowsData = this.rows;
            this.tenantId = this.$store.getters.getTenantId;
            let payload ={
                tenant_id: this.tenantId,
                count: 1000 // for now adding static count because otherwise api will only give 10 data (API should be modified.)
            }
            let {data} = await getclientId(payload);
            if (data?.data?.length > 0) {
                this.clients = data?.data.map((res)=>{return {"title": res.name, "value": res.id}})
                this.dropDownOptions.options = this.clients
                this.dropDownOptions.label = 'title'
                this.dropDownOptions.trackBy = 'value'
                // this.dropDownOptions.defaultValue = this.dropDownOptions.options[0];
            }
            this.showDropDown = true;
            this.loading = false;
        },
        computed: { 
            isFilters() {
              return this.serverParams && Object.entries(this.serverParams).length;
            },
            columnFilterOptions() {
              return this.getFilterOptions(this.columnOption, this.rows);
            },
            columnOption() {
                return this.columns.map((el) => ({
                  ...el,
                  config: 
                  {
                      ...el.config,
                      isLoading: !!this.searchLoading[el.field] || false,
                  },
                }));
            },
            computedColumns() {
                // use option list from server if it is:
                const options = this.columnFilterOptions;
                // had to check for empty object
                if (options && Object.entries(options).length) {
                  return this.columns.map((col) => {
                    const filtered = col.config?.filter ?? true;
                    if (col.prefetchedOptions && col.prefetchedOptions.length) {
                      return {
                        ...col,
                        filterOptions: {
                          enabled: filtered,
                          filterDropdownItems: col.prefetchedOptions,
                        },
                      };
                    } else {
                      return {
                        ...col,
                        filterOptions: {
                          enabled: filtered,
                          filterDropdownItems: options[col.field],
                        },
                      };
                    }
                  });
                } else {
                  // TODO remove/rework default filters
                  return this.columns.map((col) => {
                    const filtered = col.config?.filter ?? true;
                    return {
                      ...col,
                      filterOptions: filtered
                        ? {
                          enabled: true,
                          placeholder: "All",
                          filterDropdownItems: this.rows
                            .filter((row) => row[col.field])
                            .map((row) => ({ id: row.field_type_id ?? uuid.v4(), name: row[col.field] }))
                            .filter(onlyUnique),
                        }
                        : undefined,
                    };
                  });
                }
            }
        },
        methods: {
            FormatDateNew,
            getNrnName(value) {
                if (value?.length > 90) {
                    return value.substring(0, 40) + "..."
                } else {
                    return value;
                }
            },
            showCreditHit(rowData) {
                let credit_per_hit = rowData?.credit_per_hit
                if (this.currentClient && this.currentClient?.value) {
                    let customClient = rowData?.clients?.find(clnt => clnt?.client_id == this.currentClient?.value);
                    if (customClient && customClient?.client_id) {
                        credit_per_hit = customClient.credit_per_hit;
                    }
                } else {
                    let customTenant = rowData?.tenants?.find(tnt => tnt?.tenant_id == this.tenantId)
                    if (customTenant && customTenant?.tenant_id) {
                        credit_per_hit = customTenant.credit_per_hit;
                    }
                }
                return credit_per_hit;
            },
            showServiceCharge(rowData) {
                let service_charge_per_hit = rowData?.service_charge_per_hit
                if (this.currentClient && this.currentClient?.value) {
                    let customClient = rowData?.clients?.find(clnt => clnt?.client_id == this.currentClient?.value);
                    if (customClient && customClient?.client_id) {
                        service_charge_per_hit = customClient.service_charge_per_hit;
                    }
                } else {
                    let customTenant = rowData?.tenants?.find(tnt => tnt?.tenant_id == this.tenantId)
                    if (customTenant && customTenant?.tenant_id) {
                        service_charge_per_hit = customTenant.service_charge_per_hit;
                    }
                }
                return service_charge_per_hit;
            },
            checkCustomData(rowData) {
                let isCustomData = false;
                if (this.currentClient && this.currentClient?.value) {
                    if (rowData?.clients?.length > 0 && rowData?.clients?.some(clnt => clnt?.client_id == this.currentClient?.value)) {
                        isCustomData = true;
                    }
                } else {
                    if (rowData?.tenants?.length > 0) {
                        isCustomData = true;
                    }
                }
                return isCustomData;
            },
            async setCreditSourceList() {
                try {
                    // let clientId = this.$store.getters.getClientId
                    const filterParams = this.makeFilterParams();
                    let qParams
                    qParams = new URLSearchParams(this.currentPageData);
                    if (filterParams) {
                        qParams = new URLSearchParams({...this.currentPageData, ...filterParams});
                    }
                    const { data } = await creditAndSourceList(qParams);
                    this.rows = data.data;
                    this.filteredRowsData = this.rows;
                    this.pageData.total = this.filteredRowsData.length;
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    if (Array.isArray(error?.response?.data?.detail)) {
                        this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
                    } else {
                        this.$toast.error(error?.response?.data?.detail);
                    }
                }
            },
            async assignedClients(data) {
                this.modalData = data;
                this.currentModalTitle = "Clients";
                if (this.$refs.assignedListModal.showModal()) {
                    this.$refs.assignedListModal.showModal();
                }
            },
            async onPageChange(data, resetPage = false) {
                this.currentPageData = {
                    page: data.currentPage,
                    limit: data.currentPerPage,
                };
                if (resetPage) this.currentPageData.page = 1;
                await this.setCreditSourceList();
            },
            async handleFilterActivity(value) {
              this.currentClient = value
            },
            async onColumnFilter({ columnFilters }) {
                this.currentPageData.page = 1;
                this.serverParams = columnFilters ? { ...columnFilters } : null;
                await this.setCreditSourceList();
            },
            selectionChanged(e) {
              // console.log('e :>> ', e);
              if (e) {
                this.isSelectionChanged = e.selectedRows && e.selectedRows.length !== 0;
                this.$emit('on-selected-rows-change', e.selectedRows)
              }
            },
            makeFilterParams() {
                let filterParams = {};
                if (this.serverParams) {
                  Object.keys(this.serverParams).map((key) => {
                    if (key == 'order_date' || key == 'sale_date') {
                      filterParams[key] = this.serverParams[key] ? this.serverParams[key]["label"] : delete filterParams[key];
                    } else {
                      filterParams[key] = this.serverParams[key] ? this.serverParams[key]["name"] : delete filterParams[key];
                    }
                    if (this.serverParams[key]["name"] === undefined) {
                      delete filterParams[key];
                    }
                  });
                }
                return filterParams;
            },
            setFilterRef(el) {
                if (el) {
                  this.filterRefs.push(el);
                }
            },
            getFilterValue(col) {
                // isFilters ? filters[column.field] : null
                if (this.isFilters) {
                  return this.serverParams[col.field] ?? this.serverParams[col.query_key];
                } else return null;
            },
             getFilterOptions(column){
                // get unique filter options array
                let filterData = []
                
                if (column?.filter_type == 'client_users' && this.rows?.length > 0) {
                    this.rows.forEach((rowfilter) => {
                        rowfilter?.custom_clients?.map((rowData) => {
                            if (!filterData?.some?.(data => data?.name == rowData?.client_name)) {
                                filterData?.push(
                                    {
                                        id: rowData?.client_id,
                                        name: rowData?.client_name
                                    }
                                    )
                                }
                        })
                    })
                } else if (column?.filter_type == 'tenant_users' && this.rows?.length > 0) {
                    this.rows.forEach((rowfilter) => {
                        rowfilter?.custom_tenants?.map((rowData) => {
                            if (!filterData?.some?.(data => data?.name == rowData?.tenant_name)) {
                                filterData?.push(
                                    {
                                        id: rowData?.tenant_id,
                                        name: rowData?.tenant_name
                                    }
                                    )
                                }
                        })
                    })
                } else {
                    filterData = this.rows
                    .filter((row) => row[column.field])
                    .map((row) => (
                        { 
                            id: row.field_type_id ?? uuid.v4(), 
                            label: row[column.field],
                            name: column?.filter_type == 'date' ? FormatDateNew(new Date (row[column.field])) : row[column?.field]
                        }
                    )
                    )
                    if (filterData && filterData?.length > 0) {
                        const uniqueMap = new Map();
                        filterData = filterData.forEach(item => {
                            uniqueMap.set(item.name, item);
                        });
                        return Array.from(uniqueMap.values());
                    }
                }
                return filterData;
            },
            updateColumnFilters(column, value){
                if (column?.filter_type == 'date') {
                    this.filters[column.field]  =  value.label ? [value.label] : [] ;
                } else {
                    this.filters[column.field]  =  value.name ? [value.name] : [] ;
                }
                let result = this.rows;
                for (let filter in this.filters) {
                    if (this.filters[filter]?.length) {
                        if (column?.filter_type == 'client_users') {
                            result = result?.filter((el) => el?.custom_clients?.some(clnt => clnt?.client_name == value?.name))
                        } else if (column?.filter_type == 'tenant_users') {
                            result = result?.filter((el) => el?.tenant_users?.some(tnt => tnt?.user_name == value?.name))
                        } else {
                            result = result.filter((el) => this.filters[filter].includes(el[filter]));
                        }
                    }
                }
                this.filteredRowsData = result;
                this.pageData.total = this.filteredRowsData.length;
            },
            async clearFilters() {
              this.filters = {};
              this.filteredRowsData = this.rows;
              this.pageData.total = this.filteredRowsData.length;
            },
        }

    }
</script>
<style lang="scss" scoped>
@import "@/assets/styles/functions.scss";

.dropRight{
    background-color: #0d69d5;
}

</style>
